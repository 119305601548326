import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import axios from 'axios';
import { ClothPiecePostState } from "common-ts/dist/models/ClothPiece";
import { fetchClothPiecesList } from "./ClothPieceListRedux";
import { fetchClothsList } from "./ClothListRedux";
import objectHash from "object-hash";

const initialState: ClothPiecePostState = {
    clothPieceDraft: {
      clothId: "",
      inputDateTime: "",
      initialSafeLength: 0,
      width: 0,
      identityHash: ""
    },

    clothPieceCreateDialogOpen: false,

    status: APIRequestStatus.Idle,
    error: ''
}

const clothPieceCreate = createSlice({
    name: 'clothPieceCreate',
    initialState,
    reducers: {
        updateVal(state, action) {
          state.clothPieceDraft = action.payload;
          if(state.clothPieceDraft.clothId){
            var clothPieceObjectHash = objectHash.sha1(state.clothPieceDraft);
            state.clothPieceDraft.identityHash = clothPieceObjectHash;
          }
          console.log(`State values: ${JSON.stringify(state.clothPieceDraft)}`);
        },
        updateDialogOpen(state, action) {
          state.clothPieceCreateDialogOpen = action.payload;
        }
    },
    extraReducers(builder) {
        builder
          .addCase(postNewClothPiece.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
          })
          .addCase(postNewClothPiece.fulfilled, (state, action) => {
            // We only close the dialog if the post is successful
            if (action.payload.clothPieceCreateSuccess) {
              state.clothPieceCreateDialogOpen = false;
            }

            state.status = APIRequestStatus.Success;
            console.log("Post success.");
          })
          .addCase(postNewClothPiece.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            console.log("Post failed.");
          })
      }
})

export const postNewClothPiece = createAsyncThunk('clothPieceCreate/postNewClothPiece', async (arg, thunkAPI) => {
    const state: any = thunkAPI.getState();

    const response = await axios({
      method: 'post',
      url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/cloth/clothpieces',
      data: state.clothPieceCreate.clothPieceDraft,
    });

    thunkAPI.dispatch(fetchClothsList());
    thunkAPI.dispatch(fetchClothPiecesList());

    return response.data
})

export const { updateVal, updateDialogOpen } = clothPieceCreate.actions

export default clothPieceCreate.reducer