import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import Product, { Collection } from 'common-ts/dist/models/Product';
import { ProductsOnlyList } from 'common-ts/dist/models/Product';
import { BodyMeasurementsForSize } from 'common-ts/dist/models/UserMeasurements';
import { RegularSizeValue } from 'common-ts/dist/models/Cart';
import axios from 'axios';

// Define the initial state using that type
const initialState: ProductsOnlyList = {
  productsDictionary: {},
  productsList: [],
  productSizeAvailabilityDict: {},

  statusSizeAvailability: APIRequestStatus.Idle,
  status: APIRequestStatus.Idle,
  error: null,
}

export const fetchProductsList = createAsyncThunk('productsList/fetchProductsList', async (arg, thunkAPI) => {
  const response = await axios({
    method: 'get',
    url: 'https://ck8k2pv7c9.execute-api.ap-southeast-1.amazonaws.com/ProductAPIProduction/products?show-all-variables-xyz123=true',
  });
  
  thunkAPI.dispatch(fetchProductsSizeAvailabilityDict(response.data.productIdList));

  return response.data
})

export const fetchProductsListWithUpdateCache = createAsyncThunk('productsList/fetchProductsListWithUpdateCache', async () => {
  const response = await axios({
    method: 'get',
    url: 'https://ck8k2pv7c9.execute-api.ap-southeast-1.amazonaws.com/ProductAPIProduction/products?update-get-products-cache=true'
  });
  return response.data
})


// For Collection CMS Preview
export const fetchProductsSizeAvailabilityDict = createAsyncThunk('productsList/fetchProductsSizeAvailabilityDict', async (payload: string[]) => {

  var measurementsArray = [];
  measurementsArray.push(BodyMeasurementsForSize[RegularSizeValue.XXS]);
  measurementsArray.push(BodyMeasurementsForSize[RegularSizeValue.XS]);
  measurementsArray.push(BodyMeasurementsForSize[RegularSizeValue.S]);
  measurementsArray.push(BodyMeasurementsForSize[RegularSizeValue.M]);
  measurementsArray.push(BodyMeasurementsForSize[RegularSizeValue.L]);
  measurementsArray.push(BodyMeasurementsForSize[RegularSizeValue.XL]);
  measurementsArray.push(BodyMeasurementsForSize[RegularSizeValue.XXL]);

  const response = await axios({
    method: 'post',
    url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/availability/product-size',
    data: JSON.stringify({
      productIdArray: payload,
      measurementsArray: measurementsArray
    })
  });

  return response.data
})

export const productSlice = createSlice({
  name: 'productsList',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProductsList.pending, (state, action) => {
        state.status = APIRequestStatus.RequestInProgress;
      })
      .addCase(fetchProductsList.fulfilled, (state, action) => {
        // Payload is already in Object format.

        // Product
        const productDetailArray: Product[] = action.payload.products;
        state.productsList = productDetailArray;
        
        state.productsDictionary = {};
        for (let key in productDetailArray) {
          const productDetail: Product = productDetailArray[key];
          state.productsDictionary[productDetail.productId] = productDetail;
        }

        state.status = APIRequestStatus.Success;
      })
      .addCase(fetchProductsList.rejected, (state, action) => {
        state.status = APIRequestStatus.Failure;
        // state.error = action.error.message
        console.log("fetch is failure");
      })
      .addCase(fetchProductsSizeAvailabilityDict.pending, (state, action) => {
        state.statusSizeAvailability = APIRequestStatus.RequestInProgress;
      })
      .addCase(fetchProductsSizeAvailabilityDict.fulfilled, (state, action) => {
        // Payload is already in Object format.

        // Product Size Availability Dict
        state.productSizeAvailabilityDict = action.payload;

        state.statusSizeAvailability = APIRequestStatus.Success;
      })
      .addCase(fetchProductsSizeAvailabilityDict.rejected, (state, action) => {
        state.statusSizeAvailability = APIRequestStatus.Failure;
      })
  }
})

// Action creators are generated for each case reducer function
// export const { addProductToList } = productSlice.actions

export default productSlice.reducer