import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import axios from 'axios';
import { fetchCategoryReviewList } from './CategoryReviewListRedux';

const initialState: any = {
  categoryReviewDraft: {},

  dialogOpen: false,

  status: APIRequestStatus.Idle,
  error: ''
};

const newCategoryReviewSlice = createSlice({
  name: 'categoryReviewUpdate',
  initialState,
  reducers: {
    updateVal(state, action) {
      state.categoryReviewDraft = action.payload;
      console.log(`State values: `);
      console.log(state.categoryReviewDraft);
    },
    updateDialogOpen(state, action) {
      state.dialogOpen = action.payload;
    }
  },
  extraReducers(builder) {
    builder
    .addCase(postUpdateCategoryReview.pending, (state, action) => {
      state.status = APIRequestStatus.RequestInProgress;
    })
    .addCase(postUpdateCategoryReview.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.dialogOpen = false;
      }

      state.status = APIRequestStatus.Success;
      console.log("Post success.");
    })
    .addCase(postUpdateCategoryReview.rejected, (state, action) => {
      state.status = APIRequestStatus.Failure;
      console.log("Post failed.");
    })
  }
});

export const postUpdateCategoryReview = createAsyncThunk(
  'categoryReviewList/postUpdateCategoryReview',
  async (arg, thunkAPI) => {
    const state: any = thunkAPI.getState();
    var data = {...state.categoryReviewUpdate.categoryReviewDraft};

      console.log('To be sent: ' + JSON.stringify(data));

      // Nanti tambahin endpoint-nya
      const response = await axios({
        method: 'post',
        url: 'https://ck8k2pv7c9.execute-api.ap-southeast-1.amazonaws.com/ProductAPIProduction/category-reviews/update', 
        data: data
      });

      thunkAPI.dispatch(fetchCategoryReviewList());

      return response.data;
    }
);

export const { updateVal, updateDialogOpen } = newCategoryReviewSlice.actions;

export default newCategoryReviewSlice.reducer;
