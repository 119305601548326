import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { APIRequestStatus } from "common-ts/dist/models/APIRequestStatus";
import { ServiceTicketListState } from "common-ts/dist/models/ServiceTicket";

const initialState : ServiceTicketListState = {
    serviceTicketList: [],

    status: APIRequestStatus.Idle,
    error: null
}

export const fetchServiceTicketList = createAsyncThunk('serviceTicketList/fetchServiceTicketList', async () => {
    const response = await axios({
        method: 'get',
        url: 'https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/service-ticket',
    });
    return response.data
})

export const serviceTicketSlice = createSlice({
    name: 'serviceTicketList',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
      builder
        .addCase(fetchServiceTicketList.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
        })
        .addCase(fetchServiceTicketList.fulfilled, (state, action) => {
            state.serviceTicketList = action.payload.ticketList;

            state.status = APIRequestStatus.Success;
        })
        .addCase(fetchServiceTicketList.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            // state.error = action.error.message
            console.log("fetch is failure");
        })
    }
})

export default serviceTicketSlice.reducer