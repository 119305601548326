import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';

export interface ProductAnalyticsItem {
  productId: string;
  viewEvent: number;
  visitEvent: number;
  selectSizeEvent: number;
  checkoutEvent: number;
  purchaseEvent: number;
  firstTimestamp: string;
  lastTimestamp: string;
}

const initialState: any = {
  productAnalyticsList: []
};

export const fetchProductAnalyticsList = createAsyncThunk(
  'productAnalyticsList/fetchProductAnalyticsList',
  async () => {
    const response = await axios({
      method: 'get',
      url: ''
    });
    return response.data;
  }
);

export const productAnalyticsListSlice = createSlice({
  name: 'productAnalyticsList',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchProductAnalyticsList.pending, (state, action) => {
        state.getStatus = APIRequestStatus.RequestInProgress;
      })
      .addCase(fetchProductAnalyticsList.fulfilled, (state, action) => {
        const productAnalytics = action.payload.productAnalyticsList;

        state.productAnalyticsListDict = JSON.parse(productAnalytics);
        state.getStatus = APIRequestStatus.Success;
      })
      .addCase(fetchProductAnalyticsList.rejected, (state, action) => {
        state.getStatus = APIRequestStatus.Failure;
        console.log('Prodpanel category review fetch is a failure');
      });
  }
});

export default productAnalyticsListSlice.reducer;

export const dummyProductAnalyticsList = [
  {
    purchaseEvent: 1,
    checkoutEvent: 1,
    selectSizeEvent: 0,
    firstTimestamp: '2024-08-07T04:50:00.000Z',
    visitEvent: 0,
    lastTimestamp: '2024-08-08T08:49:32.787Z',
    viewEvent: 1,
    productId: '10'
  }
];
