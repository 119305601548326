import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import Product, { Collection } from 'common-ts/dist/models/Product';
import { ProductsOnlyList } from 'common-ts/dist/models/Product';
import axios from 'axios';
import { fetchProductsList } from './ProductListRedux';
import { Collections } from '@mui/icons-material';

// Sementara dibuat di sini dulu interfacenya, mungkin nanti akan dipindah ke Product.ts di common-ts
interface CollectionsList {
  collectionsList: Collection[];
  collectionsDictionary: {};
  discoveryCollectionsOrder: string[];
  efficientCollectionsOrder: string[];
  efficientProductsOrder: string[];
  status: APIRequestStatus;
  error: null | Error;
}

const initialState: CollectionsList = {
  collectionsList: [],
  collectionsDictionary: {},
  discoveryCollectionsOrder: [],
  efficientCollectionsOrder: [],
  efficientProductsOrder: [],


  status: APIRequestStatus.Idle,
  error: null,
}

export const collectionSlice = createSlice({
  name: 'collectionsList',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProductsList.pending, (state, action) => {
        state.status = APIRequestStatus.RequestInProgress;
      })
      .addCase(fetchProductsList.fulfilled, (state, action) => {
        // Collection
        const collectionArray: Collection[] = action.payload.collections;
        state.collectionsDictionary = {};
        for (let key in collectionArray) {
          const collectionDetail: Collection = collectionArray[key];
          state.collectionsDictionary[collectionDetail.collectionId] = collectionDetail;
        }

        state.collectionsList = action.payload.collections;

        state.discoveryCollectionsOrder = action.payload.discoveryCollectionsOrder;
        state.efficientCollectionsOrder = action.payload.efficientCollectionsOrder;
        state.efficientProductsOrder = action.payload.efficientProductsOrder;

        state.status = APIRequestStatus.Success;
      })
      .addCase(fetchProductsList.rejected, (state, action) => {
        state.status = APIRequestStatus.Failure;
        // state.error = action.error.message
        console.log("fetch is failure");
      })
  }
})

// Action creators are generated for each case reducer function
// export const { addProductToList } = productSlice.actions

export default collectionSlice.reducer

export {}