import { Box, Card, Typography } from "@mui/material";
import { useAppSelector } from "src/reduxhooks";
import OrdersTable from "./OrdersTable";

function DailyKPIs() {
    const ordersListStatus = useAppSelector(state => state.ordersList.ordersList);

    let totalRevenueToday = 0; 
    let numberOfOrders = 0; 

    for (let i = 0; i < ordersListStatus.length; i++) {

      const currentOrder: any = ordersListStatus[i];

      if (currentOrder.paymentStatus !== "capture" && currentOrder.paymentStatus !== "settlement") {
        continue;
      }

      // Get date of order in GMT+7
      const todayDateStr = new Date().toLocaleDateString('en-US', {timeZone: 'Asia/Jakarta', day: "2-digit", month: "2-digit"});
      var orderDateStr = new Date(currentOrder.creationDateTime).toLocaleDateString('en-US', {timeZone: 'Asia/Jakarta', day: "2-digit", month: "2-digit"});

      if (todayDateStr === orderDateStr) {
        totalRevenueToday += currentOrder.totalPaymentAmount;
        numberOfOrders += 1;
      }
    }
  
    return (
      <Card>
        <Typography style={{
          fontSize: '17px',
          marginTop: '18px',
          marginBottom: '4px',
          marginLeft: '15px',
        }}>
          <b>Revenue Today:</b> Rp {totalRevenueToday.toString().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          {' / '}{'2500000'.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </Typography>
        <Typography style={{
          fontSize: '14px',
          fontWeight: 300,
          marginTop: '12px',
          marginBottom: '39px',
          marginLeft: '15px',
        }}>
          <b>Number Of Orders: </b> {numberOfOrders} ORDERS
        </Typography>
        <OrdersTable ordersList={ordersListStatus} />
      </Card>
    );
  }

export default DailyKPIs;