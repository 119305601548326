import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import axios from 'axios';
import { ClothPostState } from 'common-ts/dist/models/Cloth';
import { fetchClothsList } from "./ClothListRedux";

const initialState: ClothPostState = {
    clothDraft: {
      clothId: "",
      possibleWidths: [],
      provisionedVersionIsCollapsedVersion: true,
      provisionedVersionNumber: 0,
      typicalWidth: 0,
    },

    clothCreateDialogOpen: false,

    status: APIRequestStatus.Idle,
    error: ''
}

const newProductSlice = createSlice({
    name: 'postNewCloth',
    initialState,
    reducers: {
        updateVal(state, action) {
          state.clothDraft = action.payload;
          console.log(`State values: ${JSON.stringify(state.clothDraft)}`);
        },
        updateDialogOpen(state, action) {
          state.clothCreateDialogOpen = action.payload;
        }
    },
    extraReducers(builder) {
        builder
          .addCase(postNewCloth.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
          })
          .addCase(postNewCloth.fulfilled, (state, action) => {
            // We only close the dialog if the post is successful
            if (action.payload.success) {
              state.clothCreateDialogOpen = false;
            }

            state.status = APIRequestStatus.Success;
            console.log("Post success.");
          })
          .addCase(postNewCloth.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            console.log("Post failed.");
          })
      }
})

export const postNewCloth = createAsyncThunk('product/postNewCloth', async (arg, thunkAPI) => {
    const state: any = thunkAPI.getState();

    const response = await axios({
      method: 'post',
      url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/cloth',
      data: state.postNewCloth.clothDraft,
    });

    thunkAPI.dispatch(fetchClothsList());

    return response.data
})

export const { updateVal, updateDialogOpen } = newProductSlice.actions

export default newProductSlice.reducer