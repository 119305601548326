import { Document, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Fragment } from "react";

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    titleContainer:{
        flexDirection: 'row',
        marginTop: 24,
    },
    reportTitle:{
        color: '#61dafb',
        letterSpacing: 4,
        fontSize: 25,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 36,
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
            fontSize: 12,
            fontStyle: 'bold',
    },
    label: {
        width: 60
    },
    headerContainer: {
        marginTop: 36
    },
    billTo: {
        marginTop: 20,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique'
    },
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
    },
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '60%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
    tableHeaderContainer: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    total: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
    reportFooterTitle:{
        fontSize: 12,
        textAlign: 'center',
        textTransform: 'uppercase',
    }
    
  });
  
  const OrderInvoice = () => (
            <Document>
                <Page size="A4" style={styles.page}>
                    <Image style={styles.logo} src={'https://cdn1.iconfinder.com/data/icons/programing-development-8/24/react_logo-512.png'} />
                    <View style={styles.titleContainer}>
                        <Text style={styles.reportTitle}>Test</Text>
                    </View>
                    <Fragment>
                        <View style={styles.invoiceNoContainer}>
                            <Text style={styles.label}>Invoice No:</Text>
                            <Text style={styles.invoiceDate}>20221018</Text>
                        </View >
                        <View style={styles.invoiceDateContainer}>
                            <Text style={styles.label}>Date: </Text>
                            <Text >18-10-2022</Text>
                        </View >
                    </Fragment>
                    {/* Invoice Header */}
                    <View style={styles.headerContainer}>
                        <Text style={styles.billTo}>Bill To:</Text>
                        <Text>Company Name</Text>
                        <Text>123 Company Street, Jakarta, Indonesia</Text>
                        <Text>+62 812 3456 7890</Text>
                        <Text>company.name@mail.com</Text>
                    </View >
                    <View style={styles.tableContainer}>
                        {/* Table Head */}
                        <View style={styles.tableHeaderContainer}>
                            <Text style={styles.description}>Item Description</Text>
                            <Text style={styles.qty}>Qty</Text>
                            <Text style={styles.rate}>@</Text>
                            <Text style={styles.amount}>Amount</Text>
                        </View>
                        {/* Table row */}
                        <View style={styles.row} >
                            <Text style={styles.description}>Lorem ipsum dolor sit amet</Text>
                            <Text style={styles.qty}>4</Text>
                            <Text style={styles.rate}>Rp 15.000</Text>
                            <Text style={styles.amount}>Rp 60.000</Text>
                        </View>
                        <InvoiceTableBlankSpace />
                        <InvoiceTableBlankSpace />
                        <InvoiceTableBlankSpace />
                        <View style={styles.row}>
                            <Text style={styles.description}>TOTAL</Text>
                            <Text style={styles.total}>RP 60.000</Text>
                        </View>
                    </View>
                    
                    <View style={styles.titleContainer}>
                        <Text style={styles.reportFooterTitle}>Thank you for your business</Text>
                    </View>
                </Page>
            </Document>
);

const InvoiceTableBlankSpace = () => {
    const rows = (
        <View style={styles.row}>
            <Text style={styles.description}>-</Text>
            <Text style={styles.qty}>-</Text>
            <Text style={styles.rate}>-</Text>
            <Text style={styles.amount}>-</Text>
        </View>
    )
    return rows
};
  
  export default OrderInvoice