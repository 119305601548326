import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { APIRequestStatus } from "common-ts/dist/models/APIRequestStatus";
import { ClothPieceOnlyList } from "common-ts/dist/models/ClothPiece";

const initialState : ClothPieceOnlyList = {
    clothPiecesDictionary: {},
    clothPiecesList: [],
    status: APIRequestStatus.Idle,
    error: null
}

export const fetchClothPiecesList = createAsyncThunk('clothPiecesList/fetchClothPiecesList', async () => {
    const response = await axios({
        method: 'get',
        url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/cloth/clothpieces',
    });
    return response.data
})

export const fetchClothPiecesListWithQuery = createAsyncThunk('clothPiecesList/fetchClothPiecesListWithQuery', async () => {
    //later need to add query input into params
    const response = await axios({
        method: 'get',
        url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/cloth/clothpieces?clothid=kainkuning1',
    });
    return response.data
})

export const clothPieceSlice = createSlice({
    name: 'clothList',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
      builder
        .addCase(fetchClothPiecesList.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
        })
        .addCase(fetchClothPiecesList.fulfilled, (state, action) => {
            // Do something here after successful fetch
            const clothpieceDetailArray: [] = action.payload.clothList;
            state.clothPiecesList = clothpieceDetailArray;
            state.clothPiecesDictionary = {};

            for (let key in clothpieceDetailArray) {
                const clothpieceDetail: any = clothpieceDetailArray[key];
                
                // Generate color codes if we find identity hash
                if(clothpieceDetail.identityHash){
                    var colorCodeArray = []
                    for (let i = 0; i < 24; i+=6) {
                        var colorCode = clothpieceDetail.identityHash.substring(i, i + 6)
                        colorCodeArray.push(colorCode)
                    }
                    clothpieceDetail.colorCodes = colorCodeArray
                }
                
                state.clothPiecesDictionary[`${clothpieceDetail.clothId}+${clothpieceDetail.inputDateTime}`] = clothpieceDetail;
            }

            state.status = APIRequestStatus.Success;
        })
        .addCase(fetchClothPiecesList.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            // state.error = action.error.message
            console.log("fetch is failure");
        })
    }
})

export default clothPieceSlice.reducer