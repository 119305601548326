import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import axios from 'axios';
import { ServiceTicketPostState } from 'common-ts/dist/models/ServiceTicket';
import { fetchServiceTicketList } from "./ServiceTicketListRedux";

const initialState: ServiceTicketPostState = {
    serviceTicketDraft: {
        timestamp: '',
        category: '',
        descriptionList: '',
        phoneNumber: '', 
        userSub: '',
    },


    dialogOpen: false,

    status: APIRequestStatus.Idle,
    error: ''
}

const newServiceTicketSlice = createSlice({
    name: 'serviceTicketCreate',
    initialState,
    reducers: {
        updateVal(state, action) {
            state.serviceTicketDraft = action.payload
            console.log(`State values: ${JSON.stringify(state.serviceTicketDraft)}`)
        }, 
        updateDialogOpen(state, action) {
            state.dialogOpen = action.payload;
        }
    },
    extraReducers(builder) {
        builder
          .addCase(postNewServiceTicket.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
          })
          .addCase(postNewServiceTicket.fulfilled, (state, action) => {
            state.status = APIRequestStatus.Success;

            if (action.payload.postSerpostNewServiceTicketSuccess) {
              state.dialogOpen = false;
            } 
          })
          .addCase(postNewServiceTicket.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            console.log("fetch is failure");
          })
      }
})

export const postNewServiceTicket = createAsyncThunk('serviceTicketCreate/postNewServiceTicket', async (arg, thunkAPI) => {
    const state: any = thunkAPI.getState();

    const response = await axios({
      method: 'post',
      url: 'https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/service-ticket',
      data: state.serviceTicketCreate.serviceTicketDraft
    });

    thunkAPI.dispatch(fetchServiceTicketList());

    return response.data
})

export const { updateVal, updateDialogOpen } = newServiceTicketSlice.actions

export default newServiceTicketSlice.reducer