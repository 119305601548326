import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import axios from 'axios';
import { ProductPostState } from 'common-ts/dist/models/Product';
import { fetchProductsList } from "./ProductListRedux";

const initialState: ProductPostState = {
    productDraft: {
        productBackendVersion: "", // KEEP EMPTY, AUTOFILLED IN BACKEND
        measurementNeeded: [], // KEEP EMPTY, AUTOFILLED IN BACKEND

        allClothIdList: [], // KEEP EMPTY, AUTOFILLED IN BACKEND
        allRequestPatternList: [], // KEEP EMPTY, AUTOFILLED IN BACKEND
        allUnitItemIdList: [], // KEEP EMPTY, AUTOFILLED IN BACKEND
        allMidstockIdList: [], // KEEP EMPTY, AUTOFILLED IN BACKEND
        allMidstockPatternList: [], // KEEP EMPTY, AUTOFILLED IN BACKEND

        cacheSetMeasurementNeeded: [],
        cacheSetMeasurementRules: [],
        cacheSetSizeMeasurementValues: [],
        cacheSetSizeNames: [],

        midstockMeasurementNeeded: [],
        midstockMeasurementRules: [],
        midstockSizeMeasurementValues: [],
        midstockSizeNames: [],

        resourceDecisionTree: {
          nodeType: "NodeAnd",
          childrenNodes: [],
        }
    },

    productCreateDialogOpen: false,

    status: APIRequestStatus.Idle,
    error: ''
}

const newProductSlice = createSlice({
    name: 'productCreate',
    initialState,
    reducers: {
        updateVal(state, action) {
            state.productDraft = action.payload
            console.log(`State values: ${JSON.stringify(state.productDraft)}`)
        },
        updateDialogOpen(state, action) {
            state.productCreateDialogOpen = action.payload;
        }
    },
    extraReducers(builder) {
        builder
          .addCase(postNewProduct.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
          })
          .addCase(postNewProduct.fulfilled, (state, action) => {
            state.status = APIRequestStatus.Success;

            if (action.payload.postProductSuccess) {
              state.productCreateDialogOpen = false;
            } 
          })
          .addCase(postNewProduct.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            console.log("fetch is failure");
          })
      }
})

export const postNewProduct = createAsyncThunk('productCreate/postNewProduct', async (arg, thunkAPI) => {
    const state: any = thunkAPI.getState();

    const response = await axios({
      method: 'post',
      url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/product',
      data: state.productCreate.productDraft
    });

    thunkAPI.dispatch(fetchProductsList());

    return response.data
})

export const { updateVal, updateDialogOpen } = newProductSlice.actions

export default newProductSlice.reducer