import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import axios from 'axios';
import { MidstockTakePostState } from 'common-ts/dist/models/Midstock';
import { fetchMidstockList } from "./MidstockListPostRedux";

const initialState: MidstockTakePostState = {
    midstockTakeDraft: {
        midstockId: '',
        amountToTake: 0,
        takeFromOrderProvision: false,
        takeFromOrderProvisionData: {},
    },

    dialogOpen: false,

    status: APIRequestStatus.Idle,
    error: ''
}

const slice = createSlice({
    name: 'midstockStockTake',
    initialState,
    reducers: {
        updateVal(state, action) {
          state.midstockTakeDraft = action.payload;
          console.log(`State values: ${JSON.stringify(state.midstockTakeDraft)}`);
        },
        updateDialogOpen(state, action) {
          state.dialogOpen = action.payload;
        }
    },
    extraReducers(builder) {
        builder
          .addCase(postMidstockStockTake.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
          })
          .addCase(postMidstockStockTake.fulfilled, (state, action) => {
            // We only close the dialog if the post is successful
            if (action.payload.success) {
              state.dialogOpen = false;
            }

            state.status = APIRequestStatus.Success;
            console.log("Post success.");
          })
          .addCase(postMidstockStockTake.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            console.log("Post failed.");
          })
      }
})

export const postMidstockStockTake = createAsyncThunk('midstockStockTake/postMidstockStockTake', async (arg, thunkAPI) => {
    const state: any = thunkAPI.getState();

    const response = await axios({
      method: 'post',
      url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/midstock/take',
      data: state.midstockStockTake.midstockTakeDraft,
    });

    thunkAPI.dispatch(fetchMidstockList());

    return response.data
})

export const { updateVal, updateDialogOpen } = slice.actions

export default slice.reducer