import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { APIRequestStatus } from "common-ts/dist/models/APIRequestStatus";
import InventoryRequest from "common-ts/dist/models/InventoryRequest";

const initialState = {
    inventoryRequestsDictionary: {},
    inventoryRequestsList: [],

    status: APIRequestStatus.Idle,
    error: null
}

export const fetchInventoryClothRequest = createAsyncThunk('inventoryRequest/fetchInventoryClothRequestsList', async () => {
    const response = await axios({
        method: 'get',
        url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/inventoryrequest/cloth',
    });

    return response.data
})

export const inventoryClothRequestSlice = createSlice({
    name: 'inventoryClothRequest',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
      builder
        .addCase(fetchInventoryClothRequest.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
        })
        .addCase(fetchInventoryClothRequest.fulfilled, (state, action) => {
            // Do something here after successful fetch
            const inventoryRequestsArray: InventoryRequest[] = action.payload.inventoryClothRequestList;
            state.inventoryRequestsList = inventoryRequestsArray;
            state.inventoryRequestsDictionary = {};

            // *Change detail type to inventory request object later*
            for (let key in inventoryRequestsArray) {
                const inventoryRequestDetail: InventoryRequest = inventoryRequestsArray[key];
                state.inventoryRequestsDictionary[`${inventoryRequestDetail.clothId}+${inventoryRequestDetail.creationDateTime}`] = inventoryRequestDetail;
            }

            state.status = APIRequestStatus.Success;
        })
        .addCase(fetchInventoryClothRequest.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            // state.error = action.error.message
            console.log("fetch is failure");
        })
    }
})

export default inventoryClothRequestSlice.reducer