import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import { CategoryReviewListDict, CategoryReviewListState } from 'common-ts/dist/models/CategoryReview';

const initialState: CategoryReviewListState = {
  // Read
  categoryReviewRecap: {},
  categoryReviewListDict: {},
  getStatusRecap: APIRequestStatus.Idle,
  getStatus: APIRequestStatus.Idle,

  // Write
  categoryReviewDraft: {}, // Tipenya any
  fileDraft: {}, // Untuk menyimpan gambar yang di-upload : any
  postStatus: APIRequestStatus.Idle
};

export const fetchCategoryReviewList = createAsyncThunk(
  'categoryReviewList/fetchCategoryReviewList',
  async () => {
    const response = await axios({
      method: 'get',
      url: 'https://ck8k2pv7c9.execute-api.ap-southeast-1.amazonaws.com/ProductAPIProduction/category-reviews?category=entire'
    });
    return response.data;
  }
);

export const categoryReviewListSlice = createSlice({
  name: 'categoryReviewList',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCategoryReviewList.pending, (state, action) => {
        state.getStatus = APIRequestStatus.RequestInProgress;
      })
      .addCase(fetchCategoryReviewList.fulfilled, (state, action) => {
        const categoryReviewList = action.payload.categoryReviewList;
        let tempCategoryReviewListDict: CategoryReviewListDict = {}
        
        categoryReviewList.forEach((categoryReview) => {
          const category = categoryReview.category;

          if (!tempCategoryReviewListDict[category]) {
            tempCategoryReviewListDict[category] = {
              categoryReviewList: [],
              lastEvaluatedKey: ''
            };
          }

          tempCategoryReviewListDict[category].categoryReviewList.push(
            categoryReview
          );
        });

        state.categoryReviewListDict = {...tempCategoryReviewListDict}
        state.getStatus = APIRequestStatus.Success;
      })
      .addCase(fetchCategoryReviewList.rejected, (state, action) => {
        state.getStatus = APIRequestStatus.Failure;
        console.log('Prodpanel category review fetch is a failure');
      });
  }
});

export default categoryReviewListSlice.reducer;
