import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { APIRequestStatus } from "common-ts/dist/models/APIRequestStatus";
import { UnitItemListState } from "common-ts/dist/models/UnitItem";

const initialState : UnitItemListState = {
    unitItemList: [],

    status: APIRequestStatus.Idle,
    error: null
}

export const fetchUnitItemsList = createAsyncThunk('unitItemList/fetchUnitItemsList', async () => {
    const response = await axios({
        method: 'get',
        url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/unititem',
    });
    return response.data
})

export const slice = createSlice({
    name: 'unitItemList',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
      builder
        .addCase(fetchUnitItemsList.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
        })
        .addCase(fetchUnitItemsList.fulfilled, (state, action) => {
            state.unitItemList = action.payload.unitItemList;

            state.status = APIRequestStatus.Success;
        })
        .addCase(fetchUnitItemsList.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            // state.error = action.error.message
            console.log("fetch is failure");
        })
    }
})

export default slice.reducer