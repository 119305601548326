import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { APIRequestStatus } from "common-ts/dist/models/APIRequestStatus";

const initialState = {
    filesDictionary: {},
    filesList: [],
    
    productFilesDict: {},

    status: APIRequestStatus.Idle,
    error: null
}

export const fetchFilesList = createAsyncThunk('filesList/fetchFilesList', async () => {
    const response = await axios({
        method: 'get',
        url: 'https://w3q706jlua.execute-api.ap-southeast-1.amazonaws.com/FileSystemAPIProduction/files',
    });
    // console.log(response.data)
return response.data
})

export const fileSlice = createSlice({
    name: 'filesList',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
      builder
        .addCase(fetchFilesList.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
        })
        .addCase(fetchFilesList.fulfilled, (state, action) => {
            // Do something here after successful fetch
            const fileDetailArray: [] = action.payload.fileList;

            state.filesList = fileDetailArray;
            state.filesDictionary = {};

            for (let key in fileDetailArray) {
                const fileDetail: any = fileDetailArray[key];
                state.filesDictionary[fileDetail.fileKey] = fileDetail;
                if (fileDetail?.fileCategory === "product-image" && fileDetail?.fileCategoryData?.productId) {
                    if (state.productFilesDict[fileDetail.fileCategoryData.productId] === undefined) {
                        state.productFilesDict[fileDetail.fileCategoryData.productId] = [];
                    }
                    state.productFilesDict[fileDetail.fileCategoryData.productId].push(fileDetail.fileURL);
                }
            }

            state.status = APIRequestStatus.Success;
        })
        .addCase(fetchFilesList.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            // state.error = action.error.message
            console.log("fetch is failure");
        })
    }
})

export default fileSlice.reducer