import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import axios from 'axios';
import { UnitItemPostState } from 'common-ts/dist/models/UnitItem';
import { fetchUnitItemsList } from "./UnitItemListPostRedux";

const initialState: UnitItemPostState = {
    unitItemDraft: {
        unitItemId: '',
        amountUnits: '',
        reorderTriggerAmount: 0,
        reorderPurchaseAmount: 0,
        unitItemIsProvisionedByOrder: true,
    },

    dialogOpen: false,

    status: APIRequestStatus.Idle,
    error: ''
}

const slice = createSlice({
    name: 'unitItemCreate',
    initialState,
    reducers: {
        updateVal(state, action) {
          state.unitItemDraft = action.payload;
          console.log(`State values: ${JSON.stringify(state.unitItemDraft)}`);
        },
        updateDialogOpen(state, action) {
          state.dialogOpen = action.payload;
        }
    },
    extraReducers(builder) {
        builder
          .addCase(postNewUnitItem.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
          })
          .addCase(postNewUnitItem.fulfilled, (state, action) => {
            // We only close the dialog if the post is successful
            if (action.payload.success) {
              state.dialogOpen = false;
            }

            state.status = APIRequestStatus.Success;
            console.log("Post success.");
          })
          .addCase(postNewUnitItem.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            console.log("Post failed.");
          })
      }
})

export const postNewUnitItem = createAsyncThunk('unitItemCreate/postNewUnitItem', async (arg, thunkAPI) => {
    const state: any = thunkAPI.getState();

    const response = await axios({
      method: 'post',
      url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/unititem',
      data: state.unitItemCreate.unitItemDraft,
    });

    thunkAPI.dispatch(fetchUnitItemsList());

    return response.data
})

export const { updateVal, updateDialogOpen } = slice.actions

export default slice.reducer