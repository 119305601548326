import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { APIRequestStatus } from "common-ts/dist/models/APIRequestStatus";
import { UnitItemListState } from "common-ts/dist/models/UnitItem";

interface UserListState {
    userList: any[],
  
    status: APIRequestStatus,
    error: string | null,
}

const initialState : UserListState = {
    userList: [],

    status: APIRequestStatus.Idle,
    error: null
}

export const fetchUserList = createAsyncThunk('unitItemList/fetchUserList', async () => {
    const response = await axios({
        method: 'get',
        url: 'https://ojm2bfbydd.execute-api.ap-southeast-1.amazonaws.com/UserAPIProd/activity',
    });
    return response.data
})

export const slice = createSlice({
    name: 'userList',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
      builder
        .addCase(fetchUserList.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
        })
        .addCase(fetchUserList.fulfilled, (state, action) => {
            state.userList = action.payload.userList;

            state.status = APIRequestStatus.Success;
        })
        .addCase(fetchUserList.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            // state.error = action.error.message
            console.log("fetch is failure");
        })
    }
})

export default slice.reducer