import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { APIRequestStatus } from "common-ts/dist/models/APIRequestStatus";
import { OrderPaymentPostState } from "common-ts/dist/models/Orders";
import { fetchOrdersList } from "./OrderListRedux";

const initialState : OrderPaymentPostState = {
    orderDraft: {},

    orderUpdateDialogOpen: false,

    status: APIRequestStatus.Idle,
    error: null
}

export const postOrderPaymentUpdate = createAsyncThunk('orderPaymentUpdate/postOrderPaymentUpdate', async (arg: {order_id: string, transaction_status: string}, thunkAPI) => {
    const state: any = thunkAPI.getState();

    const response = await axios({
        method: 'post',
        url: 'https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/paymentnotify',
        data: arg
    });

    thunkAPI.dispatch(fetchOrdersList(""));

    return response.data
})

export const orderPaymentUpdateSlice = createSlice({
    name: 'orderPaymentUpdate',
    initialState,
    reducers: {
        updateVal(state, action) {
            state.orderDraft = action.payload;
            console.log(`State values: ${JSON.stringify(state.orderDraft)}`);
        },
        updateDialogOpen(state, action) {
            state.orderUpdateDialogOpen = action.payload;
        }
    },
    extraReducers(builder) {
      builder
        .addCase(postOrderPaymentUpdate.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
        })
        .addCase(postOrderPaymentUpdate.fulfilled, (state, action) => {
            state.orderDraft = {};
            state.orderUpdateDialogOpen = false;

            state.status = APIRequestStatus.Success;
        })
        .addCase(postOrderPaymentUpdate.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            console.log("fetch is failure");
        })
    }
})

export const { updateVal, updateDialogOpen } = orderPaymentUpdateSlice.actions

export default orderPaymentUpdateSlice.reducer