import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import Product, {
  Collection,
  UIBlockElement
} from 'common-ts/dist/models/Product';
import { ProductsOnlyList } from 'common-ts/dist/models/Product';
import axios from 'axios';
import { fetchProductsList, fetchProductsListWithUpdateCache } from './ProductListRedux';
import { useAppSelector } from 'src/reduxhooks';
import { enableMapSet } from 'immer';
import { cloneElement } from 'react';

enableMapSet();

function deepCopy<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

// Sementara dibuat di sini dulu interfacenya, mungkin nanti akan dipindah ke Product.ts di common-ts
interface CollectionsListDraft {
  collectionsListDraft: Collection[];
  discoveryCollectionsOrderDraft: string[];
  editedCollection: Set<string>;
  status: APIRequestStatus;
  error: null | Error;
}

const initialState: CollectionsListDraft = {
  collectionsListDraft: [],
  discoveryCollectionsOrderDraft: [],
  editedCollection: new Set<string>(),
  status: APIRequestStatus.Idle,
  error: null
};

const compareCollections = (filteredCollections, originalCollections) => {
  const originalCollectionMap = new Map(
    originalCollections.map((c) => [c.collectionId, JSON.stringify(c)])
  );

  console.log(originalCollectionMap)

  return filteredCollections.filter((filteredCollection) => {
    const originalCollectionString = originalCollectionMap.get(
      filteredCollection.collectionId
    );

    return (
      !originalCollectionString ||
      originalCollectionString !== JSON.stringify(filteredCollection)
    );
  });
};

export const postUpdateCollection = createAsyncThunk(
  'collectionUpdate/postUpdateCollection',
  async (arg, thunkAPI) => {
    const state: any = thunkAPI.getState();

    const originalCollectionList = state.collectionList.collectionsList

    // // Hanya koleksi yang diubah yang akan di-push
    // const filteredCollectionsListDraft =
    //   state.collectionUpdate.collectionsListDraft.filter((collection) =>
    //     state.collectionUpdate.editedCollection.has(collection.collectionId)
    //   );

    // Setidaknya daftar koleksi yang akan ditampilkan setiap anggotanya harus punya SALAH SATU (atau ketiganya?) dari ketiga list di dalam objek koleksinya
    const nonEmptyCollectionsListDraft =
      state.collectionUpdate.collectionsListDraft.filter(
        (collection) =>
          collection.discoveryListOfElements.length > 0 ||
          collection.efficientOrderOfItems.length > 0 ||
          collection.productItemsList.length > 0
      );

    const nonEmptyCollectionIds = new Set(
      nonEmptyCollectionsListDraft.map((c) => c.collectionId)
    );

    // Hanya koleksi yang diubah dan mempunyai setidaknya satu anggota di salah satu dari ketiga list di objeknya yang akan di-push
    const filteredCollectionsListDraft =
      state.collectionUpdate.collectionsListDraft.filter(
        (collection) =>
          state.collectionUpdate.editedCollection.has(
            collection.collectionId
          ) && nonEmptyCollectionIds.has(collection.collectionId)
      );

    // Web hanya akan menampilkan koleksi yang mempunyai setidaknya satu anggota di salah satu dari ketiga list di objeknya
    const filteredDiscoveryCollectionsOrderDraft =
      state.collectionUpdate.discoveryCollectionsOrderDraft.filter(
        (collectionId) => nonEmptyCollectionIds.has(collectionId)
      );

      console.log(filteredCollectionsListDraft)

    // Hanya baru atau koleksi yang mengalami perubahan dari list asli yang akan di-push
    const filteredAndComparedCollectionsListDraft = compareCollections(
      filteredCollectionsListDraft,
      originalCollectionList
    );

    const data = {
      collectionsListDraft: filteredAndComparedCollectionsListDraft,
      discoveryCollectionsOrderDraft: filteredDiscoveryCollectionsOrderDraft
    };

    // console.log(data);
    // return {};

    const response = await axios({
      method: 'put',
      url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/collection',
      data: JSON.stringify(data)
    });

    thunkAPI.dispatch(fetchProductsListWithUpdateCache());

    thunkAPI.dispatch(fetchProductsList());

    return response.data;
  }
);

export const slice = createSlice({
  name: 'collectionUpdate',
  initialState,
  reducers: {
    // Create Collection
    createCollection(state, action) {
      const newCollection = action.payload;

      console.log(newCollection);

      const existingCollectionIndex = state.collectionsListDraft.findIndex(
        (collection) => collection.collectionId === newCollection.collectionId
      );

      if (existingCollectionIndex === -1) {
        state.collectionsListDraft = [
          deepCopy(newCollection),
          ...state.collectionsListDraft
        ];
      }

      const existingOrderIndex = state.discoveryCollectionsOrderDraft.findIndex(
        (collectionId) => collectionId === newCollection.collectionId
      );

      if (existingOrderIndex === -1) {
        state.discoveryCollectionsOrderDraft = [
          newCollection.collectionId,
          ...state.discoveryCollectionsOrderDraft
        ];
      }
    },
    // Reorder Collection
    reorderCollection(state, action) {
      const discoveryCollectionsOrder = action.payload;

      state.discoveryCollectionsOrderDraft = [...discoveryCollectionsOrder];
    },
    // Delete Collection (Soft Delete)
    deleteCollection(state, action) {
      const deletedCollectionId = action.payload;

      state.discoveryCollectionsOrderDraft = [
        ...state.discoveryCollectionsOrderDraft.filter(
          (collectionId) => collectionId !== deletedCollectionId
        )
      ];
    },
    // Update Collection Product List
    updateCollectionProductList(state, action) {
      const collectionId = action.payload.collectionId;
      const productItemsList = action.payload.productItemsList;

      const collectionIndex = state.collectionsListDraft.findIndex(
        (collection) => collection.collectionId === collectionId
      );

      // Ganti daftar produk apabila koleksi ditemukan di daftar koleksi
      if (collectionIndex !== -1) {
        state.collectionsListDraft[collectionIndex].productItemsList = [
          ...productItemsList
        ];
      }

      // Tambah koleksi ke daftar koleksi yang diedit
      if (!state.editedCollection.has(collectionId)) {
        state.editedCollection.add(collectionId);
      }
    },
    // Update Collection Efficient Order Of Items
    updateCollectionEfficientOrderOrItems(state, action) {
      const collectionId = action.payload.collectionId;
      const efficientOrderOfItems = action.payload.efficientOrderOfItems;

      const collectionIndex = state.collectionsListDraft.findIndex(
        (collection) => collection.collectionId === collectionId
      );

      // Ganti daftar produk apabila koleksi ditemukan di daftar koleksi
      if (collectionIndex !== -1) {
        state.collectionsListDraft[collectionIndex].efficientOrderOfItems = [
          ...efficientOrderOfItems
        ];
      }

      // Tambah koleksi ke daftar koleksi yang diedit
      if (!state.editedCollection.has(collectionId)) {
        state.editedCollection.add(collectionId);
      }
    },
    // Create Collection UI Element
    createCollectionElement(state, action) {
      const collectionId = action.payload.collectionId;
      const UIBlockElement = action.payload.UIBlockElement;

      const collectionIndex = state.collectionsListDraft.findIndex(
        (collection) => collection.collectionId === collectionId
      );

      // Tambahkan elemen koleksi baru apabila koleksi ditemukan di daftar koleksi
      if (collectionIndex !== -1) {
        state.collectionsListDraft[
          collectionIndex
        ].discoveryListOfElements.push(deepCopy(UIBlockElement));
      }

      // Tambah koleksi ke daftar koleksi yang diedit
      if (!state.editedCollection.has(collectionId)) {
        state.editedCollection.add(collectionId);
      }
    },
    // Update Collection UI Element
    updateCollectionElement(state, action) {
      const collectionId = action.payload.collectionId;
      const UIBlockElement = action.payload.UIBlockElement;
      const UIBlockElementIndex = action.payload.UIBlockElementIndex;

      const collectionIndex = state.collectionsListDraft.findIndex(
        (collection) => collection.collectionId === collectionId
      );

      // Ubah elemen koleksi baru apabila koleksi ditemukan di daftar koleksi
      if (collectionIndex !== -1) {
        const list =
          state.collectionsListDraft[collectionIndex].discoveryListOfElements;
        state.collectionsListDraft[collectionIndex].discoveryListOfElements = [
          ...list.slice(0, UIBlockElementIndex),
          deepCopy(UIBlockElement),
          ...list.slice(UIBlockElementIndex + 1)
        ];
      }

      // Tambah koleksi ke daftar koleksi yang diedit
      if (!state.editedCollection.has(collectionId)) {
        state.editedCollection.add(collectionId);
      }
    },
    // Reorder Collection UI Element
    reorderCollectionElement(state, action) {
      const collectionId = action.payload.collectionId;
      const UIBlockElementIndex = action.payload.UIBlockElementIndex;
      const isMovedUp = action.payload.isMovedUp;

      const collectionIndex = state.collectionsListDraft.findIndex(
        (collection) => collection.collectionId === collectionId
      );
      if (
        collectionIndex === -1 ||
        UIBlockElementIndex < 0 ||
        UIBlockElementIndex >=
          state.collectionsListDraft[collectionIndex].discoveryListOfElements
            .length
      ) {
        return;
      }

      const list =
        state.collectionsListDraft[collectionIndex].discoveryListOfElements;
      const [elementToMove] = list.splice(UIBlockElementIndex, 1);
      const newIndex = isMovedUp
        ? UIBlockElementIndex - 1
        : UIBlockElementIndex + 1;

      state.collectionsListDraft[collectionIndex].discoveryListOfElements = [
        ...list.slice(0, newIndex),
        elementToMove,
        ...list.slice(newIndex)
      ];

      // Tambah koleksi ke daftar koleksi yang diedit
      if (!state.editedCollection.has(collectionId)) {
        state.editedCollection.add(collectionId);
      }
    },
    // Delete Collection UI Element
    deleteCollectionElement(state, action) {
      const collectionId = action.payload.collectionId;
      const UIBlockElementIndex = action.payload.UIBlockElementIndex;

      // Tambah koleksi ke daftar koleksi yang diedit
      const collectionIndex = state.collectionsListDraft.findIndex(
        (collection) => collection.collectionId === collectionId
      );
      if (
        collectionIndex === -1 ||
        UIBlockElementIndex < 0 ||
        UIBlockElementIndex >=
          state.collectionsListDraft[collectionIndex].discoveryListOfElements
            .length
      ) {
        return;
      }

      const list =
        state.collectionsListDraft[collectionIndex].discoveryListOfElements;
      const [removedItem] = list.splice(UIBlockElementIndex, 1);

      state.collectionsListDraft[collectionIndex].discoveryListOfElements = [
        ...list
      ];

      // Tambah koleksi ke daftar koleksi yang diedit
      if (!state.editedCollection.has(collectionId)) {
        state.editedCollection.add(collectionId);
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProductsList.pending, (state, action) => {
        state.status = APIRequestStatus.RequestInProgress;
      })
      .addCase(fetchProductsList.fulfilled, (state, action) => {
        state.collectionsListDraft = deepCopy(action.payload.collections);
        state.discoveryCollectionsOrderDraft = deepCopy(
          action.payload.discoveryCollectionsOrder
        );

        state.status = APIRequestStatus.Success;
      })
      .addCase(fetchProductsList.rejected, (state, action) => {
        state.status = APIRequestStatus.Failure;
      });
  }
});

// Action creators are generated for each case reducer function
// export const { addProductToList } = productSlice.actions

export const {
  createCollection,
  reorderCollection,
  deleteCollection,
  updateCollectionProductList,
  updateCollectionEfficientOrderOrItems,
  createCollectionElement,
  updateCollectionElement,
  reorderCollectionElement,
  deleteCollectionElement
} = slice.actions;

export default slice.reducer;
