import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import axios from 'axios';
import { ProductUpdateState } from 'common-ts/dist/models/Product';
import { fetchProductsList } from "./ProductListRedux";

const initialState: ProductUpdateState = {
    productDraft: {
    },

    productUpdateDialogOpen: false,

    status: APIRequestStatus.Idle,
    error: ''
}

const newProductSlice = createSlice({
    name: 'productUpdate',
    initialState,
    reducers: {
        updateVal(state, action) {
            state.productDraft = action.payload
            console.log(`State values: ${JSON.stringify(state.productDraft)}`)
        },
        updateDialogOpen(state, action) {
            state.productUpdateDialogOpen = action.payload;
        }
    },
    extraReducers(builder) {
        builder
          .addCase(postUpdateProduct.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
          })
          .addCase(postUpdateProduct.fulfilled, (state, action) => {
            state.status = APIRequestStatus.Success;

            if (action.payload.success) {
              state.productUpdateDialogOpen = false;
            } 
          })
          .addCase(postUpdateProduct.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            console.log("fetch is failure");
          })
      }
})

export const postUpdateProduct = createAsyncThunk('productUpdate/postUpdateProduct', async (arg, thunkAPI) => {
    const state: any = thunkAPI.getState();

    const response = await axios({
      method: 'post',
      url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/product/update',
      data: state.productUpdate.productDraft
    });

    thunkAPI.dispatch(fetchProductsList());

    return response.data
})

export const { updateVal, updateDialogOpen } = newProductSlice.actions

export default newProductSlice.reducer