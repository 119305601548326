import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import axios from 'axios';
import { MidstockUpdatePostState } from 'common-ts/dist/models/Midstock';
import { fetchMidstockList } from "./MidstockListPostRedux";

const initialState: MidstockUpdatePostState = {
    midstockDraft: {
        midstockId: '',
    },

    dialogOpen: false,

    status: APIRequestStatus.Idle,
    error: ''
}

const slice = createSlice({
    name: 'midstockUpdate',
    initialState,
    reducers: {
        updateVal(state, action) {
          state.midstockDraft = action.payload;
          console.log(`State values: ${JSON.stringify(state.midstockDraft)}`);
        },
        updateDialogOpen(state, action) {
          state.dialogOpen = action.payload;
        }
    },
    extraReducers(builder) {
        builder
          .addCase(postUpdateMidstock.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
          })
          .addCase(postUpdateMidstock.fulfilled, (state, action) => {
            // We only close the dialog if the post is successful
            if (action.payload.success) {
              state.dialogOpen = false;
            }

            state.status = APIRequestStatus.Success;
            console.log("Post success.");
          })
          .addCase(postUpdateMidstock.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            console.log("Post failed.");
          })
      }
})

export const postUpdateMidstock = createAsyncThunk('midstockUpdate/postUpdateMidstock', async (arg, thunkAPI) => {
    const state: any = thunkAPI.getState();

    const response = await axios({
      method: 'put',
      url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/midstock',
      data: state.midstockUpdate.midstockDraft,
    });

    thunkAPI.dispatch(fetchMidstockList());

    return response.data
})

export const { updateVal, updateDialogOpen } = slice.actions

export default slice.reducer