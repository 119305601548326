import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import axios from 'axios';
import { fetchCategoryReviewList } from './CategoryReviewListRedux';

const initialState: any = {
  categoryReviewDraft: {
    displayImageListDraft: [],
    categoryReviewObject: {
      timestamp: '',
      name: '',
      comment: '',
      rating: 1,
      reviewClass: 1, 
      displayImageList: '',
      productNameList: '',
      userSub: '',
      reviewId: '',
    }
  },

  dialogOpen: false,

  status: APIRequestStatus.Idle,
  error: ''
};

const newCategoryReviewSlice = createSlice({
  name: 'categoryReviewCreate',
  initialState,
  reducers: {
    updateVal(state, action) {
      state.categoryReviewDraft = action.payload;
      console.log(`State values: `);
      console.log(state.categoryReviewDraft);
    },
    updateDialogOpen(state, action) {
      state.dialogOpen = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(postNewCategoryReview.pending, (state, action) => {
        state.status = APIRequestStatus.RequestInProgress;
      })
      .addCase(postNewCategoryReview.fulfilled, (state, action) => {
        state.status = APIRequestStatus.Success;
      })
      .addCase(postNewCategoryReview.rejected, (state, action) => {
        state.status = APIRequestStatus.Failure;
        console.log('post new category review is a failure');
      });
  }
});

export const postNewCategoryReview = createAsyncThunk(
  'categoryReviewList/postNewCategoryReview',
  async (arg, thunkAPI) => {
    const state: any = thunkAPI.getState();
    var categoryReviewDraft = state.categoryReviewCreate.categoryReviewDraft;

    var postFileInformationSuccess = true;

    let displayImageListArray = [];

    const timestamp = new Date(
      categoryReviewDraft.categoryReviewObject.timestamp
    );
    const formattedTimestamp = timestamp
      .toISOString()
      .replace(/[-:]/g, '')
      .replace(/\.\d{3}Z$/, '');

    for (
      var fileIndex = 0;
      fileIndex < categoryReviewDraft.displayImageListDraft.length;
      fileIndex = fileIndex + 1
    ) {
      console.log('Uploading image number ' + (fileIndex + 1));
      var currentFileDraft: any = {};
      currentFileDraft.file =
        categoryReviewDraft.displayImageListDraft[fileIndex];

      const fileExtension = currentFileDraft.file.name.split('.').pop();
      const newFileName = `${categoryReviewDraft.categoryReviewObject.reviewId}_${fileIndex + 1}.${fileExtension}`;

      currentFileDraft.fileName = newFileName.replace(/ /g, '-');; // Filename unik untuk semua (harapannya)
      currentFileDraft.fileDataType = currentFileDraft.file.type;
      currentFileDraft.fileCategory = 'general'; // Semua review saat ini kategori file-nya general
      currentFileDraft.fileCategoryData = '';

      const signedURLResponse = await axios({
        method: 'post',
        url: 'https://w3q706jlua.execute-api.ap-southeast-1.amazonaws.com/FileSystemAPIProduction/signedurl',
        data: currentFileDraft
      });

      console.log(signedURLResponse);
      const uploadURL = signedURLResponse.data.uploadURL;
      const createdFileKey = signedURLResponse.data.fileKey;

      try {
        const uploadResult = await axios({
          method: 'put',
          url: uploadURL,
          data: currentFileDraft.file,
          headers: {
            'Content-Type': currentFileDraft.fileDataType
          }
        });

        console.log('upload to s3 success:');
        console.log(uploadResult.headers);
        console.log(uploadResult.statusText);
      } catch (err) {
        postFileInformationSuccess = false;
        console.log('Error uploading to s3:');
        console.log(err);
      }

      console.log('continue...');
      const fileURL = uploadURL.split('?')[0];

      // INI PENTING => Push URL gambar yang telah diunggah
      displayImageListArray.push(fileURL);
    }
    console.log('image uplaoded to s3');
    // Kalau berhasil upload gambar-gambarnya
    if (postFileInformationSuccess) {
      let response;

      // Gabungkan URL gambar tadi
      let tempData = {
        ...categoryReviewDraft.categoryReviewObject,
        displayImageList: JSON.stringify(displayImageListArray)
      };

      const data = JSON.stringify(tempData);

      console.log('To be sent: ' + data);

      response = await axios({
        method: 'post',
        url: 'https://ck8k2pv7c9.execute-api.ap-southeast-1.amazonaws.com/ProductAPIProduction/category-reviews', 
        data: data
      });

      thunkAPI.dispatch(fetchCategoryReviewList());

      return response.data;
    }
  }
);

export const { updateVal, updateDialogOpen } = newCategoryReviewSlice.actions;

export default newCategoryReviewSlice.reducer;
