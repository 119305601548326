import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import axios from 'axios';
import { ServiceTicketUpdatePostState } from 'common-ts/dist/models/ServiceTicket';
import { fetchServiceTicketList } from "./ServiceTicketListRedux";

const initialState: ServiceTicketUpdatePostState = {
  serviceTicketDraft: {
    serviceTicketObject: {
        finishedStatus: '',
        timestamp: '',
        category: '',
        status: '',
        descriptionList: '',
        phoneNumber: '',
        userSub: '',
    }
},
    dialogOpen: false,

    status: APIRequestStatus.Idle,
    error: ''
}

const slice = createSlice({
    name: 'serviceTicketUpdate',
    initialState,
    reducers: {
        updateVal(state, action) {
          state.serviceTicketDraft.serviceTicketObject = action.payload;
          console.log(`State values: ${JSON.stringify(state.serviceTicketDraft)}`);
        },
        updateDialogOpen(state, action) {
          state.dialogOpen = action.payload;
        }
    },
    extraReducers(builder) {
        builder
          .addCase(postUpdateServiceTicket.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
          })
          .addCase(postUpdateServiceTicket.fulfilled, (state, action) => {
            // We only close the dialog if the post is successful
            if (action.payload.success) {
              state.dialogOpen = false;
            }

            state.status = APIRequestStatus.Success;
            console.log("Post success.");
          })
          .addCase(postUpdateServiceTicket.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            console.log("Post failed.");
          })
      }
})

export const postUpdateServiceTicket = createAsyncThunk('postUpdateServiceTicket/postUpdateServiceTicket', async (arg, thunkAPI) => {
    const state: any = thunkAPI.getState();

    const response = await axios({
      method: 'put',
      url: 'https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/service-ticket',
      data: state.serviceTicketUpdate.serviceTicketDraft,
    });

    console.log(state.serviceTicketUpdate.serviceTicketDraft)

    thunkAPI.dispatch(fetchServiceTicketList());

    return response.data
})

export const { updateVal, updateDialogOpen } = slice.actions

export default slice.reducer

