import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import { APIRequestStatus } from "common-ts/dist/models/APIRequestStatus";
import { Order, OrderOnlyList } from "common-ts/dist/models/Orders";
import OrderItemLabels, { OrderShippingLabel, OrderCourierLabel, StockLabel } from "src/content/applications/Orders/OrderShippingLabel";
import { pdf } from '@react-pdf/renderer';
import Midstock from "common-ts/dist/models/Midstock";

const initialState : OrderOnlyList = {
    ordersDictionary: {},
    ordersList: [],

    status: APIRequestStatus.Idle,
    error: null
}

export const fetchOrdersList = createAsyncThunk('ordersList/fetchOrdersList', async (arg: string) => {
    const reqBody = {
        method: 'get',
        url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/orders-all',
    };

    if (arg) {
        reqBody['params'] = {
            "courierorder": arg
        };
    }

    const response = await axios(reqBody);
    return response.data
})

export const fetchUserPersonalizedLabelsPdf = createAsyncThunk('ordersList/fetchUserPersonalizedLabelsPdf', async (arg: Order, thunkAPI) => {
    var inputData: any = {
        orderData: arg,
        userPersonalInputData: {},
        inventoryRequestData: {},
    }

    // Fetch user personal input data
    var config: AxiosRequestConfig = {
        method: 'get',
        url: 'https://ojm2bfbydd.execute-api.ap-southeast-1.amazonaws.com/UserAPIProd/personalsizeinput?usersub=' + arg.userSub,
        headers: { 
        }
    };
    const response = await axios(config);
    if (response?.data?.success) {
        inputData.userPersonalInputData = response.data.content;

        // After fetching successfully, we update user personal input data the hasBeenPrinted in server
        var config2: AxiosRequestConfig = {
            method: 'post',
            url: 'https://ojm2bfbydd.execute-api.ap-southeast-1.amazonaws.com/UserAPIProd/personalsizeinput',
            headers: {
            },
            data: {
                userSub: inputData.userPersonalInputData.userSub,
                hasBeenPrinted: true,
                hasBeenPrintedDateTime: new Date().toISOString(),
            }
        };

        // We wait until update finishes and succeeds before making the pdf
        const response2 = await axios(config2);
    }

    // Fetch inventory request data
    var config3: AxiosRequestConfig = {
        method: 'get',
        url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/inventoryrequest/single',
        params: {
            "orderid": arg?.orderId ? arg.orderId : ""
        },
        headers: { 
        }
    };
    const response3 = await axios(config3);
    if (response3?.data?.content !== undefined) {
        inputData.inventoryRequestData = response3.data.content;
    }

    const blob = pdf(<OrderItemLabels inputData={inputData}/>).toBlob();

    blob.then(function (blob) {
      const fileURL = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = "Labels for Order " + arg.orderId + ".pdf";
      link.click();
    });
})

export const fetchUserShipmentLabelPdf = createAsyncThunk('ordersList/fetchUserShipmentLabelPdf', async (arg: Order, thunkAPI) => {
    var inputData: any = {
        orderData: arg,
        userPersonalInputData: {},
    }

    // Fetch user personal input data
    var config: AxiosRequestConfig = {
        method: 'get',
        url: 'https://ojm2bfbydd.execute-api.ap-southeast-1.amazonaws.com/UserAPIProd/personalsizeinput?usersub=' + arg.userSub,
        headers: { 
        }
    };
    const response = await axios(config);
    if (response?.data?.success) {
        inputData.userPersonalInputData = response.data.content;
    }

    const blob = pdf(<OrderShippingLabel inputData={inputData} isLong={false}/>).toBlob();

    blob.then(function (blob) {
      const fileURL = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = "Labels for Order " + arg.orderId + ".pdf";
      link.click();
    });
})

export const fetchUserShipmentLabelPdfLong = createAsyncThunk('ordersList/fetchUserShipmentLabelPdfLong', async (arg: Order, thunkAPI) => {
    var inputData: any = {
        orderData: arg,
        userPersonalInputData: {},
    }

    // Fetch user personal input data
    var config: AxiosRequestConfig = {
        method: 'get',
        url: 'https://ojm2bfbydd.execute-api.ap-southeast-1.amazonaws.com/UserAPIProd/personalsizeinput?usersub=' + arg.userSub,
        headers: { 
        }
    };
    const response = await axios(config);
    if (response?.data?.success) {
        inputData.userPersonalInputData = response.data.content;
    }

    const blob = pdf(<OrderShippingLabel inputData={inputData} isLong={true} />).toBlob();

    blob.then(function (blob) {
      const fileURL = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = "Labels for Order " + arg.orderId + ".pdf";
      link.click();
    });
})

export const fetchUserCourierLabelPdf = createAsyncThunk('ordersList/fetchUserCourierLabelPdf', async (arg: Order, thunkAPI) => {
    var inputData: any = {
        orderData: arg,
    }

    const blob = pdf(<OrderCourierLabel inputData={inputData} />).toBlob();

    blob.then(function (blob) {
      const fileURL = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = "Label Courier for Order " + arg.orderId + ".pdf";
      link.click();
    });
})

export const fetchStockMidstockLabelPdf = createAsyncThunk('ordersList/fetchStockMidstockLabelPdf', async (arg: Midstock, thunkAPI) => {

    const blob = pdf(<StockLabel inputData={{
        name: arg.midstockId,
    }} />).toBlob();

    blob.then(function (blob) {
      const fileURL = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = "Labels for Midstock " + arg.midstockId + ".pdf";
      link.click();
    });
})

export const orderSlice = createSlice({
    name: 'ordersList',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
      builder
        .addCase(fetchOrdersList.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
        })
        .addCase(fetchOrdersList.fulfilled, (state, action) => {
            // Do something here after successful fetch
            const orderDetailArray: [] = action.payload.ordersList;
            state.ordersList = orderDetailArray;
            state.ordersDictionary = {};

            for (let key in orderDetailArray) {
            const orderDetail: any = orderDetailArray[key];
            state.ordersDictionary[orderDetail.orderId] = orderDetail;
            }

            state.status = APIRequestStatus.Success;
        })
        .addCase(fetchOrdersList.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            // state.error = action.error.message
            console.log("fetch is failure");
        })
        .addCase(fetchUserPersonalizedLabelsPdf.pending, (state, action) => {
        })
        .addCase(fetchUserPersonalizedLabelsPdf.fulfilled, (state, action) => {
        })
        .addCase(fetchUserPersonalizedLabelsPdf.rejected, (state, action) => {
        })
        .addCase(fetchUserShipmentLabelPdf.pending, (state, action) => {
        })
        .addCase(fetchUserShipmentLabelPdf.fulfilled, (state, action) => {
        })
        .addCase(fetchUserShipmentLabelPdf.rejected, (state, action) => {
        })
        .addCase(fetchUserShipmentLabelPdfLong.pending, (state, action) => {
        })
        .addCase(fetchUserShipmentLabelPdfLong.fulfilled, (state, action) => {
        })
        .addCase(fetchUserShipmentLabelPdfLong.rejected, (state, action) => {
        })
        .addCase(fetchUserCourierLabelPdf.pending, (state, action) => {
        })
        .addCase(fetchUserCourierLabelPdf.fulfilled, (state, action) => {
        })
        .addCase(fetchUserCourierLabelPdf.rejected, (state, action) => {
        })
    }
})

export default orderSlice.reducer