import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { APIRequestStatus } from "common-ts/dist/models/APIRequestStatus";
import { ClothOnlyList } from "common-ts/dist/models/Cloth";

const initialState : ClothOnlyList = {
    clothsDictionary: {},
    clothsList: [],
    status: APIRequestStatus.Idle,
    error: null
}

export const fetchClothsList = createAsyncThunk('clothsList/fetchClothsList', async () => {
    const response = await axios({
        method: 'get',
        url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/cloth',
    });
    return response.data
})

export const clothSlice = createSlice({
    name: 'clothList',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
      builder
        .addCase(fetchClothsList.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
        })
        .addCase(fetchClothsList.fulfilled, (state, action) => {
            // Do something here after successful fetch
            const clothDetailArray: [] = action.payload.clothList;
            state.clothsList = clothDetailArray;
            state.clothsDictionary = {};

            for (let key in clothDetailArray) {
            const clothDetail: any = clothDetailArray[key];
            state.clothsDictionary[clothDetail.clothId] = clothDetail;
            }

            state.status = APIRequestStatus.Success;
        })
        .addCase(fetchClothsList.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            // state.error = action.error.message
            console.log("fetch is failure");
        })
    }
})

export default clothSlice.reducer